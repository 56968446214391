exports.components = {
  "component---src-pages-case-study-js": () => import("./../../../src/pages/caseStudy.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-page-js": () => import("./../../../src/pages/privacyPolicyPage.js" /* webpackChunkName: "component---src-pages-privacy-policy-page-js" */),
  "component---src-pages-recruitment-js": () => import("./../../../src/pages/recruitment.js" /* webpackChunkName: "component---src-pages-recruitment-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-site-policy-page-js": () => import("./../../../src/pages/sitePolicyPage.js" /* webpackChunkName: "component---src-pages-site-policy-page-js" */)
}

